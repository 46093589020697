import React from "react";
import KeyCard from "./KeyCard";

const KeyStrength = () => {
  return (
    <div className="bg-white w-full px-5 py-[60px] md:px-20 flex flex-col items-center ">
      <h2 className="mb-10">
        What makes Sasom Academy the ideal choice for you
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
        <KeyCard
          icon={
            <svg
              width="65"
              height="65"
              viewBox="0 0 65 65"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                width="65"
                height="65"
                rx="12.2417"
                fill="#009EC5"
                fillOpacity="0.1"
              />
              <path
                opacity="0.4"
                d="M32.25 23.1771V44.8844C32.0198 44.8844 31.776 44.8438 31.5864 44.7354L31.5323 44.7083C28.9323 43.2865 24.3958 41.7969 21.4573 41.4042L21.0646 41.35C19.7646 41.1875 18.7083 39.9688 18.7083 38.6688V22.3104C18.7083 20.699 20.0219 19.4802 21.6333 19.6156C24.4771 19.8458 28.7833 21.2813 31.1937 22.7844L31.5323 22.9875C31.7354 23.1094 31.9927 23.1771 32.25 23.1771Z"
                fill="#009EC5"
              />
              <path
                d="M45.7917 22.324V38.6688C45.7917 39.9688 44.7354 41.1875 43.4354 41.35L42.9885 41.4042C40.0365 41.7969 35.4865 43.3 32.8865 44.7355C32.7104 44.8438 32.4938 44.8844 32.25 44.8844V23.1771C32.5073 23.1771 32.7646 23.1094 32.9677 22.9875L33.1979 22.8386C35.6083 21.3219 39.9281 19.873 42.7719 19.6292H42.8531C44.4646 19.4938 45.7917 20.699 45.7917 22.324Z"
                fill="#009EC5"
              />
              <path
                d="M26.4948 28.5125H23.4479C22.8927 28.5125 22.4323 28.0521 22.4323 27.4969C22.4323 26.9416 22.8927 26.4812 23.4479 26.4812H26.4948C27.05 26.4812 27.5104 26.9416 27.5104 27.4969C27.5104 28.0521 27.05 28.5125 26.4948 28.5125Z"
                fill="#009EC5"
              />
              <path
                d="M27.5104 32.575H23.4479C22.8927 32.575 22.4323 32.1146 22.4323 31.5594C22.4323 31.0041 22.8927 30.5437 23.4479 30.5437H27.5104C28.0656 30.5437 28.5261 31.0041 28.5261 31.5594C28.5261 32.1146 28.0656 32.575 27.5104 32.575Z"
                fill="#009EC5"
              />
            </svg>
          }
          title="Comprehensive Curriculum"
          description="Sasom Academy distinguishes itself through its comprehensive curriculum, addressing the latest industry trends and guaranteeing aspiring tech enthusiasts a thorough and balanced education"
        />
        <KeyCard
          icon={
            <svg
              width="33"
              height="33"
              viewBox="0 0 33 33"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.4"
                d="M24.375 4.0625H8.12499C5.13229 4.0625 2.70833 6.47292 2.70833 9.43854V23.0615C2.70833 26.0271 5.13229 28.4375 8.12499 28.4375H24.375C27.3677 28.4375 29.7917 26.0271 29.7917 23.0615V9.43854C29.7917 6.47292 27.3677 4.0625 24.375 4.0625Z"
                fill="#009EC5"
              />
              <path
                d="M25.7292 11.849H18.9583C18.4031 11.849 17.9427 11.3885 17.9427 10.8333C17.9427 10.2781 18.4031 9.8177 18.9583 9.8177H25.7292C26.2844 9.8177 26.7448 10.2781 26.7448 10.8333C26.7448 11.3885 26.2844 11.849 25.7292 11.849Z"
                fill="#009EC5"
              />
              <path
                d="M25.7292 17.2656H20.3125C19.7573 17.2656 19.2969 16.8052 19.2969 16.25C19.2969 15.6948 19.7573 15.2344 20.3125 15.2344H25.7292C26.2844 15.2344 26.7448 15.6948 26.7448 16.25C26.7448 16.8052 26.2844 17.2656 25.7292 17.2656Z"
                fill="#009EC5"
              />
              <path
                d="M25.7292 22.6823H23.0208C22.4656 22.6823 22.0052 22.2219 22.0052 21.6667C22.0052 21.1115 22.4656 20.651 23.0208 20.651H25.7292C26.2844 20.651 26.7448 21.1115 26.7448 21.6667C26.7448 22.2219 26.2844 22.6823 25.7292 22.6823Z"
                fill="#009EC5"
              />
              <path
                d="M11.5104 15.9656C13.238 15.9656 14.6385 14.5651 14.6385 12.8375C14.6385 11.1099 13.238 9.70938 11.5104 9.70938C9.7828 9.70938 8.38229 11.1099 8.38229 12.8375C8.38229 14.5651 9.7828 15.9656 11.5104 15.9656Z"
                fill="#009EC5"
              />
              <path
                d="M12.5937 17.7531C11.876 17.6854 11.1312 17.6854 10.4135 17.7531C8.13853 17.9698 6.31041 19.7708 6.09374 22.0458C6.0802 22.2354 6.13436 22.425 6.26978 22.5604C6.4052 22.6958 6.58124 22.7906 6.77082 22.7906H16.25C16.4396 22.7906 16.6292 22.7094 16.751 22.574C16.8729 22.4385 16.9406 22.249 16.9271 22.0594C16.6969 19.7708 14.8823 17.9698 12.5937 17.7531Z"
                fill="#009EC5"
              />
            </svg>
          }
          title="Hands-On Learning"
          description="Sasom Academy  is an educational platform where students actively engage with real-world experiences, skills, or materials to enhance their understanding of a course."
        />
        <KeyCard
          icon={
            <svg
              width="33"
              height="33"
              viewBox="0 0 33 33"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.4"
                d="M12.1875 2.70833C8.63959 2.70833 5.75522 5.5927 5.75522 9.14062C5.75522 12.6208 8.47709 15.4375 12.025 15.5594C12.1333 15.5458 12.2417 15.5458 12.3229 15.5594C12.35 15.5594 12.3636 15.5594 12.3906 15.5594C12.4042 15.5594 12.4042 15.5594 12.4177 15.5594C15.8844 15.4375 18.6063 12.6208 18.6198 9.14062C18.6198 5.5927 15.7354 2.70833 12.1875 2.70833Z"
                fill="#009EC5"
              />
              <path
                d="M19.0667 19.1615C15.2885 16.6427 9.12709 16.6427 5.32188 19.1615C3.60209 20.3125 2.65417 21.8698 2.65417 23.5354C2.65417 25.201 3.60209 26.7448 5.30834 27.8823C7.20417 29.1552 9.69584 29.7917 12.1875 29.7917C14.6792 29.7917 17.1708 29.1552 19.0667 27.8823C20.7729 26.7313 21.7208 25.1875 21.7208 23.5083C21.7073 21.8427 20.7729 20.299 19.0667 19.1615Z"
                fill="#009EC5"
              />
              <path
                opacity="0.4"
                d="M27.0698 9.93958C27.2865 12.5667 25.4177 14.8687 22.8313 15.1802C22.8177 15.1802 22.8177 15.1802 22.8042 15.1802H22.7636C22.6823 15.1802 22.6011 15.1802 22.5333 15.2073C21.2198 15.275 20.0146 14.8552 19.1073 14.0833C20.5021 12.8375 21.3011 10.9687 21.1386 8.93749C21.0438 7.84062 20.6646 6.83853 20.0958 5.98541C20.6104 5.72812 21.2063 5.56562 21.8156 5.51145C24.4698 5.28124 26.8396 7.25833 27.0698 9.93958Z"
                fill="#009EC5"
              />
              <path
                d="M29.7781 22.4656C29.6698 23.7792 28.8302 24.9167 27.4219 25.6885C26.0677 26.4333 24.3615 26.7854 22.6688 26.7448C23.6438 25.8646 24.2125 24.7677 24.3208 23.6031C24.4563 21.924 23.6573 20.3125 22.0594 19.026C21.1521 18.3083 20.0958 17.7396 18.9448 17.3198C21.9375 16.4531 25.7021 17.0354 28.0177 18.9042C29.2635 19.9062 29.9 21.1656 29.7781 22.4656Z"
                fill="#009EC5"
              />
            </svg>
          }
          title="Mentorship Excellence"
          description="Sasom allows students to network with current professionals. We provide great counsel and assistance through our mentoring programme."
        />
        <KeyCard
          icon={
            <svg
              width="33"
              height="33"
              viewBox="0 0 33 33"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.4"
                d="M24.375 25.5396H23.3458C22.2625 25.5396 21.2333 25.9593 20.475 26.7177L18.1593 29.0062C17.1031 30.0489 15.3834 30.0489 14.3271 29.0062L12.0115 26.7177C11.2531 25.9593 10.2104 25.5396 9.14062 25.5396H8.125C5.87708 25.5396 4.0625 23.7386 4.0625 21.5177V6.74372C4.0625 4.52289 5.87708 2.72189 8.125 2.72189H24.375C26.6229 2.72189 28.4375 4.52289 28.4375 6.74372V21.5177C28.4375 23.725 26.6229 25.5396 24.375 25.5396Z"
                fill="#009EC5"
              />
              <path
                d="M16.25 14.0968C17.9926 14.0968 19.4053 12.6843 19.4053 10.9417C19.4053 9.19911 17.9926 7.78645 16.25 7.78645C14.5074 7.78645 13.0948 9.19911 13.0948 10.9417C13.0948 12.6843 14.5074 14.0968 16.25 14.0968Z"
                fill="#009EC5"
              />
              <path
                d="M19.8792 20.3937C20.976 20.3937 21.6125 19.175 21.0031 18.2677C20.0823 16.9 18.2948 15.9791 16.25 15.9791C14.2052 15.9791 12.4177 16.9 11.4969 18.2677C10.8875 19.175 11.524 20.3937 12.6208 20.3937H19.8792Z"
                fill="#009EC5"
              />
            </svg>
          }
          title="Barrier-Free Training"
          description="We provide access to class recordings for 30days. Our training ensures that learning is accessible and inclusive for individuals of diverse backgrounds or abilities."
        />
        <KeyCard
          icon={
            <svg
              width="33"
              height="33"
              viewBox="0 0 33 33"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M28.5594 9.45208C27.4083 8.17916 25.4854 7.54271 22.6958 7.54271H22.3708V7.48854C22.3708 5.21354 22.3708 2.39687 17.2792 2.39687H15.2208C10.1292 2.39687 10.1292 5.22708 10.1292 7.48854V7.55625H9.80417C7.00104 7.55625 5.09167 8.19271 3.94063 9.46562C2.6 10.9552 2.64062 12.9594 2.77604 14.3271L2.78958 14.4219L2.925 15.8437C2.93854 15.8573 2.96562 15.8844 2.99271 15.8979C3.43958 16.1958 3.9 16.4937 4.3875 16.7646C4.57708 16.8865 4.78021 16.9948 4.98333 17.1031C7.29896 18.376 9.84479 19.2292 12.4313 19.649C12.5531 20.9219 13.1083 22.4115 16.074 22.4115C19.0396 22.4115 19.6219 20.9354 19.7167 19.6219C22.4792 19.175 25.1469 18.2135 27.5573 16.8052C27.6385 16.7646 27.6927 16.724 27.7604 16.6833C28.3833 16.3312 28.9656 15.9521 29.5344 15.5323C29.5615 15.5187 29.5885 15.4917 29.6021 15.4646L29.6563 14.9771L29.724 14.3406C29.7375 14.2594 29.7375 14.1917 29.751 14.0969C29.8594 12.7292 29.8323 10.8604 28.5594 9.45208ZM17.726 18.7281C17.726 20.1635 17.726 20.3802 16.0604 20.3802C14.3948 20.3802 14.3948 20.1229 14.3948 18.7417V17.0354H17.726V18.7281ZM12.0656 7.54271V7.48854C12.0656 5.18646 12.0656 4.33333 15.2208 4.33333H17.2792C20.4344 4.33333 20.4344 5.2 20.4344 7.48854V7.55625H12.0656V7.54271Z"
                fill="#009EC5"
              />
              <path
                opacity="0.4"
                d="M27.7604 16.6563C27.6927 16.6969 27.625 16.7375 27.5573 16.7781C25.1469 18.1865 22.4792 19.1344 19.7167 19.5948C19.6083 20.8948 19.0396 22.3844 16.074 22.3844C13.1083 22.3844 12.5396 20.9083 12.4313 19.6219C9.8448 19.2156 7.29896 18.3625 4.98334 17.076C4.78021 16.9677 4.57709 16.8594 4.3875 16.7375C3.9 16.4667 3.43959 16.1688 2.99271 15.8708C2.96563 15.8573 2.93854 15.8302 2.925 15.8167L3.75104 24.6323C4.03542 27.3271 5.14584 30.1031 11.1042 30.1031H21.4229C27.3813 30.1031 28.4917 27.3271 28.776 24.6188L29.6292 15.4375C29.6156 15.4646 29.5885 15.4917 29.5615 15.5052C28.9792 15.925 28.3833 16.3177 27.7604 16.6563Z"
                fill="#009EC5"
              />
            </svg>
          }
          title="Career-Ready Certification"
          description="Sasom Academy ensures internationally & locally recognized qualification indicating an individual's readiness and competence for a specific profession. "
        />
        <KeyCard
          icon={
            <svg
              width="33"
              height="33"
              viewBox="0 0 33 33"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.4"
                d="M16.25 2.70834C12.7021 2.70834 9.81772 5.59271 9.81772 9.14063C9.81772 12.6208 12.5396 15.4375 16.0875 15.5594C16.1958 15.5458 16.3042 15.5458 16.3854 15.5594C16.4125 15.5594 16.4261 15.5594 16.4531 15.5594C16.4667 15.5594 16.4667 15.5594 16.4802 15.5594C19.9469 15.4375 22.6688 12.6208 22.6823 9.14063C22.6823 5.59271 19.7979 2.70834 16.25 2.70834Z"
                fill="#009EC5"
              />
              <path
                d="M23.1292 19.1615C19.351 16.6427 13.1896 16.6427 9.38438 19.1615C7.66459 20.3125 6.71667 21.8698 6.71667 23.5354C6.71667 25.201 7.66459 26.7448 9.37084 27.8823C11.2667 29.1552 13.7583 29.7917 16.25 29.7917C18.7417 29.7917 21.2333 29.1552 23.1292 27.8823C24.8354 26.7313 25.7833 25.1875 25.7833 23.5083C25.7698 21.8427 24.8354 20.299 23.1292 19.1615Z"
                fill="#009EC5"
              />
            </svg>
          }
          title="Expert Instructors"
          description="Sasom Academy have highly skilled individuals with advanced knowledge who teach and guide others in a specific subject or field."
        />
      </div>
    </div>
  );
};

export default KeyStrength;
